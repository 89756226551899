import React from 'react';
import { useI18next } from 'gatsby-plugin-react-i18next';

import { MenuLayout } from '../../components/menu-layout.component';
import { FooterNavigation } from '../../components/footer-navigation.component';
import StepInstruction from '../../components/step-instruction.component';

const steps = [
    { step: "1. 採取した容器に吸水シートを巻きつけてください。", stepImg: "/img/steps/step3-1.png" },
    { step: "2. 次に、緩衝材を巻きつけてください。", stepImg: "/img/steps/step3-2.jpg" },
    { step: "3. 密封袋（バイオパウチ）に貼ってあるラベルに手書きで名前、生年月日、採取日を記入してください。採取した容器を密封袋に入れて、封をしてください。", stepImg: "/img/steps/step3-3.jpg" },
    { step: "4. 同梱のバリアボックスを箱裏面の説明に沿って組み立ててください。密封袋（バイオパウチ）をバリアボックスに入れて、ふたを閉じてください。", stepImg: "/img/steps/step3-4.png" },
    { step: "5. 返信用封筒にバリアボックスを入れて、しっかりと封を閉じてください。法人でお申し込みいただいだ場合は、法人指定の方法で密封袋を取集してください。", stepImg: "/img/steps/step3-5.png" },
    { step: "6. お近くの郵便ポストにお出しください。切手の貼り付けは不要です。法人でお申し込みいただいだ場合は、法人指定の方法で返送お願いいたします。", stepImg: "/img/steps/step3-6.png" },
];

export default function Home() {
    const { navigate, t } = useI18next();

    return <MenuLayout className="take-test step-2">
       <div className="section-container animateIn">
            <div className="section v-center">
                <h3 className="t-subheading2">{t('STEP 3')}</h3>
                <h4 className="t-h2">{t('返送の準備')}</h4>
            </div>
            <div className="section section--steps">
                {steps.map((step, index) => (
                    <StepInstruction key={index} step={step.step} stepImg={step.stepImg} />
                ))}
            </div>
        </div>
        <FooterNavigation
            variant="orange"
            steps={3}
            activeStep={2}
            label={t('Next')}
            onClick={() => navigate('/test/done')}
        />
    </MenuLayout>;
}
